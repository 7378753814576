import React, { useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import { Button } from "../input/Button";
import configs from "../../utils/configs";
import { CreateRoomButton } from "./CreateRoomButton";
import { PWAButton } from "./PWAButton";
// import { useFavoriteRooms } from "./useFavoriteRooms";
import { usePublicRooms } from "./usePublicRooms";
import { useFeaturedScenes } from "./useFeaturedScenes";
import styles from "./HomePage.scss";
import { AuthContext } from "../auth/AuthContext";
import { createAndRedirectToNewHub } from "../../utils/phoenix-utils";
import { MediaGrid } from "../room/MediaGrid";
import { MediaTile } from "../room/MediaTiles";
import { PageContainer } from "../layout/PageContainer";
import { scaledThumbnailUrlFor } from "../../utils/media-url-utils";
import { Column } from "../layout/Column";
import { Container } from "../layout/Container";
import { SocialBar } from "../home/SocialBar";
import { SignInButton } from "./SignInButton";
import { AppLogo } from "../misc/AppLogo";
import { isHmc } from "../../utils/isHmc";
import maskEmail from "../../utils/mask-email";
/* AtC - Custom */
import { useState } from "react";

export function HomePage() {
  const auth = useContext(AuthContext);
  // const intl = useIntl();

  // const { results: favoriteRooms } = useFavoriteRooms();
  const { results: publicRooms } = usePublicRooms();
  const { results: featuredScenes } = useFeaturedScenes();

  // const sortedFavoriteRooms = Array.from(favoriteRooms).sort((a, b) => b.member_count - a.member_count);
  // const sortedPublicRooms = Array.from(publicRooms).sort((a, b) => b.member_count - a.member_count);

  /* AtC Custom */
  const [frontPageConfig, setFrontPageConfig] = useState({});
  const [frontPageCategories, setFrontPageCategories] = useState({});
  const [learningCategories, setLearningCategories] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch("https://hubsvr-public-rooms-config.aroundthecorner.se");
        const frontPageConfig = await response.json();
        setFrontPageConfig(frontPageConfig);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const updateFrontPageCategories = async () => {
    const publicRoomsMap = {};
    for (const room of publicRooms) {
      publicRoomsMap[room.id] = room;
    }

    const featuredScenesMap = {};
    for (const scene of featuredScenes) {
      featuredScenesMap[scene.id] = scene;
    }

    const fpc = [];

    for (const category in frontPageConfig.categories) {
      const rooms_or_scenes = [];
      if (frontPageConfig.categories[category].type === "rooms") {
        for (const id of frontPageConfig.categories[category].ids) {
          if (id in publicRoomsMap) {
            rooms_or_scenes.push(publicRoomsMap[id]);
          }
        }
      } else if (category.type === "scenes") {
        for (const id of frontPageConfig.categories[category].ids) {
          if (id in featuredScenesMap) {
            rooms_or_scenes.push(featuredScenesMap[id]);
          }
        }
      }

      if (rooms_or_scenes.length > 0) {
        fpc.push({
          name: frontPageConfig.categories[category].name,
          desc: frontPageConfig.categories[category].desc,
          ids: rooms_or_scenes
        });
      }
    }

    setFrontPageCategories(fpc);

    const lc = [];

    for (const learn in frontPageConfig.learning) {
      if (frontPageConfig.learning[learn].id in publicRoomsMap) {
        lc.push({
          name: frontPageConfig.learning[learn].name,
          desc: frontPageConfig.learning[learn].desc,
          side: frontPageConfig.learning[learn].side,
          room: publicRoomsMap[frontPageConfig.learning[learn].id]
        });
      }
    }

    setLearningCategories(lc);
  };

  useEffect(() => {
    updateFrontPageCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frontPageConfig, publicRooms.length]);

  const wrapInBold = chunk => <b>{chunk}</b>;
  useEffect(() => {
    const qs = new URLSearchParams(location.search);

    // Support legacy sign in urls.
    if (qs.has("sign_in")) {
      const redirectUrl = new URL("/signin", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    } else if (qs.has("auth_topic")) {
      const redirectUrl = new URL("/verify", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    }

    if (qs.has("new")) {
      qs.delete("new");
      createAndRedirectToNewHub(null, null, true, qs);
    }
  }, []);

  const canCreateRooms = !configs.feature("disable_room_creation") || auth.isAdmin;
  const email = auth.email;
  return (
    <PageContainer className={styles.homePage}>
      <Container className={styles.menu}>
        <a> Learn more about Hubs </a>
        <a> Purchase a VR headset </a>
        <a> Contact IT support </a>
      </Container>
      <Container>
        <div className={styles.hero}>
          {auth.isSignedIn ? (
            <div className={styles.signInContainer}>
              <span>
                <FormattedMessage
                  id="header.signed-in-as"
                  defaultMessage="Signed in as {email}"
                  values={{ email: maskEmail(email) }}
                />
              </span>
              <a href="#" onClick={auth.signOut} className={styles.mobileSignOut}>
                <FormattedMessage id="header.sign-out" defaultMessage="Sign Out" />
              </a>
            </div>
          ) : (
            <SignInButton mobile />
          )}
          <div className={styles.logoContainer}>
            <AppLogo />
          </div>
          <div className={styles.appInfo}>
            <div className={styles.appTitle}>
              <FormattedMessage id="ericsson-app-title" defaultMessage="Ericsson Hubs" />
            </div>
            <div className={styles.appDescription}>
              <FormattedMessage
                id="ericsson-app-description"
                defaultMessage="Immerse, explore, celebrate, engage, inspire, and connect with your team or community in a virtual space"
              />
            </div>
            {canCreateRooms && (
              <>
                <CreateRoomButton />
                <a className={styles.subbuttonLink}>How to organize an event in Hubs</a>
              </>
            )}
            <PWAButton />
            <Button
              preset="bw"
              onClick={e => {
                e.preventDefault();
                document.location = "/link";
              }}
            >
              <FormattedMessage id="join-room-button" defaultMessage="Join an existing event" />
            </Button>
          </div>
          <div className={styles.exploreBox}>
            <h4>Explore the multitude of uses for Hubs:</h4>
            <ul>
              <li><b>Team Meetings</b>: Enhance collaboration and communication.</li>
              <li><b>Networking</b>: Foster professional connections.</li>
              <li><b>Workshops/Training</b>: Boost learning and development</li>
              <li><b>Graduation/Badging Ceremonies</b>: Celebrate achievements and milestones.</li>
              <li><b>Exhibitions</b>: Showcase unique talent and creativity.</li>
              <li><b>Job/Career Fairs</b>: Connect opportunities and talent.</li>
              <li><b>Movie Watch Parties</b>: Enjoy shared entertainment experiences.</li>
              <li><b>Celebrations and Parties</b>: Make memorable moments.</li>
      </ul>
          </div>
          {/*<div className={styles.heroImageContainer}>
            <img
              alt={intl.formatMessage(
                {
                  id: "home-page.hero-image-alt",
                  defaultMessage: "Screenshot of {appName}"
                },
                { appName: configs.translation("app-name") }
              )}
              src={configs.image("home_background")}
            />
        </div>*/}
        </div>
      </Container>
      {
        /* AtC Custom */
        auth.isSignedIn === true ? (
          <>
            {learningCategories.length > 0 && (
              <Container className={styles.learningContainer}>
                <MediaGrid center>
                  {learningCategories.map(category => {
                    return (
                      <div key={category.room.id} className={styles[category.side]}>
                        <h3 className={styles.roomsHeading}>{category.name}</h3>
                        <p className={styles.roomsDescription}>{category.desc}</p>
                        <MediaTile
                          entry={category.room}
                          processThumbnailUrl={(entry, width, height) =>
                            scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                          }
                        />
                      </div>
                    );
                  })}
                </MediaGrid>
              </Container>
            )}
          </>
        ) : null
      }
      <Container className={styles.roomsContainer}>
        <div>
          <h3 className={styles.roomsHeading}>
            <FormattedMessage id="public-rooms-title" defaultMessage="Visit public rooms" />
          </h3>
          <p className={styles.roomsDescription}>
            <FormattedMessage
              id="public-rooms-description"
              defaultMessage="Explore our public rooms, each uniquely designed for different experiences. These are open spaces where anyone can enter at any time. If you'd like to use a room privately, simply create an event or meeting and select the corresponding scene."
            />
          </p>
        </div>
      </Container>
      {
        /* AtC Custom */
        auth.isSignedIn === true ? (
          <>
            {frontPageCategories.length > 0 && (
              <div>
                {frontPageCategories.map(category => {
                  return (
                    <Container key={category.name} className={styles.roomsContainer}>
                      <h3 className={styles.roomsHeading}>{category.name}</h3>
                      <p className={styles.roomsDescription}>{category.desc}</p>
                      <Column grow padding className={styles.rooms}>
                        <MediaGrid center>
                          {category.ids.map(room => {
                            return (
                              <MediaTile
                                key={room.id}
                                entry={room}
                                processThumbnailUrl={(entry, width, height) =>
                                  scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                                }
                              />
                            );
                          })}
                        </MediaGrid>
                      </Column>
                    </Container>
                  );
                })}
              </div>
            )}
          </>
        ) : null
      }
      {isHmc() ? (
        <Column center>
          <SocialBar />
        </Column>
      ) : null}
    </PageContainer>
  );
}
